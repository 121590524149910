import { logSentryError } from './logSentryError';
import { getDbwTrackingParams } from './get-dbw-tracking-params';
import { getClinicalKeywordSearched } from '../direct-booking/utils';
import { type TokensStore } from '../behaviors/tokens/types';

/** Appends params to book_online_url and returns the relative url.
 *  @example `https://www.example-pmc-url.com/book/1234?q=test` => `/book/1234?q=test`
 *  Purpose:
 *    1. Move toward allowing client-side transitions to/from directbook
 *    2. Improve SEO
 *    3. Improve dev environment and allow end-to-end testing
 * @param  {Object} provider - provider which contains the absolute direct book url and provider id
 * @param  {Object} config - customer service config
 * @param  {{consumerTrackingToken?: string,userToke?: string,searchToken?: string,bcsToken?: string}} tokens
 * @param  {{search: string}} location - react-router location object
 * @param  {Object} apptOptions - optional extra key/value params to pass into DBW
 * @returns {String} returns a relative direct book url with appended url params from apptOptions
 */
export function getRelativeParameterizedBookingUrl({
  provider,
  config,
  tokens,
  location,
  apptOptions = {}
}: {
  provider: {
    book_online_url: string | null;
    id: string;
  };
  config: {
    domains: string[];
  };
  tokens: TokensStore;
  location: {
    pathname: string;
    search: string;
  };
  apptOptions?: Record<string, string>;
}): string | null {
  if (provider.book_online_url == null) {
    // book_online_url will be null for providers NOT on Direct Booking,
    // Do not log sentry errors for this case, just pass through.
    return provider.book_online_url;
  }

  let absoluteBookOnlineUrl: URL;
  try {
    absoluteBookOnlineUrl = new URL(provider.book_online_url);
  } catch (error) {
    logSentryError('Provider booking URL is an invalid URL', {
      bookOnlineUrl: provider.book_online_url,
      providerId: provider.id
    });
    return provider.book_online_url;
  }

  const { category, clinicalKeyword } = getClinicalKeywordSearched(
    location
  ) as any;

  // We only want to append params to the URL if the destination is a domain we control.
  // It's extremely unlikely that an external booking link would look like one of ours
  // so we just match on /book/* here
  const looksLikeInternalUrl = /\/book\/\d+/.test(
    absoluteBookOnlineUrl.pathname
  );

  if (looksLikeInternalUrl) {
    const url = absoluteBookOnlineUrl;

    const allSearchParams: { [key: string]: string | string[] } = {};
    const searchParams = new URLSearchParams(location.search);
    searchParams.forEach((value, key) => {
      if (key in allSearchParams) {
        const currentValue = allSearchParams[key];
        allSearchParams[key] = Array.isArray(currentValue)
          ? [...currentValue, value]
          : [currentValue, value];
      } else {
        allSearchParams[key] = value;
      }
    });

    const paramsToAppend: Record<string, string> = {
      ...getDbwTrackingParams({ config, tokens }),
      ...(clinicalKeyword && { [category]: clinicalKeyword }),
      ...apptOptions,
      ...allSearchParams
    };

    Object.entries(paramsToAppend).forEach(
      ([key, val]: [string, string | string[]]) => {
        if (Array.isArray(val)) {
          val.forEach((v) => {
            url.searchParams.append(key, v);
          });
        } else {
          url.searchParams.append(key, val as string);
        }
      }
    );

    const relativizedBookingUrl = url.href.replace(
      absoluteBookOnlineUrl.origin,
      ''
    );
    return relativizedBookingUrl;
  }

  return provider.book_online_url;
}
